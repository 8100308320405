.ant-transfer-list {
    flex: 1;
}
.ant-transfer-list {
    height: 320px;
}

.ant-select-item-option-content {
    white-space: normal;
    overflow: auto;
}

tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}

.grey-colored-row {
    background: #eee;
}

.dark-grey-colored-row {
    background: #ddd;
}

.light-red-colored-row {
    background: #eccece;
}